@import url("https://use.typekit.net/tgp2pmi.css");
@import "~@flaticon/flaticon-uicons/css/all/all";

@tailwind base;
@layer base {
  html {
    @apply text-[#46515e];
  }
}

@tailwind components;

@tailwind utilities;
@layer utilities {
  @variants responsive {
    .masonry {
      column-gap: 1.5em;
      column-count: 1;
    }
    .masonry-sm {
      column-gap: 1.5em;
      column-count: 2;
    }
    .masonry-md {
      column-gap: 1.5em;
      column-count: 3;
    }
    .masonry-lg {
      column-gap: 1.5em;
      column-count: 4;
    }
    .break-inside {
      break-inside: avoid;
    }
  }
}

/* START GLOBAL */
body {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
main h3,
main h4 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
}
main ul {
  padding-left: 1.5rem;
  list-style-type: disc;
  margin-bottom: 1.25rem;
}
main ol {
  padding-left: 1.5rem;
  list-style-type: decimal;
  margin-bottom: 1.25rem;
}
main#gallery img {
  pointer-events: none;
}
main blockquote {
  border-left: 5px solid #ffa8bd;
  padding-left: 20px;
}
main .text-\[\#bd4292\] {
  --tw-text-opacity: 1;
  color: rgb(189 66 146 / var(--tw-text-opacity));
}

main .text-\[\#270a51\] {
  --tw-text-opacity: 1;
  color: rgb(35 28 87 / var(--tw-text-opacity));
}

main .text-\[\#ffa8bd\] {
  --tw-text-opacity: 1;
  color: rgb(255 168 189 / var(--tw-text-opacity));
}

main h2.wp-block-heading {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  --tw-text-opacity: 1;
  color: #06a7e0;
}

main h3,
main h3.wp-block-heading {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 1rem;
  --tw-text-opacity: 1;
  color: rgb(206 181 150 / var(--tw-bg-opacity));
}
main p a,
ul a {
  color: #06a7e0;
}
main p a:hover,
ul a:hover {
  text-decoration: underline;
}

main p.text-white a,
main .bg-\[\#06a7e0\] h2 {
  color: #ffffff;
}

main .swiper-slide .bg-\[\#06a7e0\] a {
  border-bottom: none;
}

main .bg-\[\#06a7e0\] p a {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}

main .bg-\[\#06a7e0\] p a {
  text-decoration: none !important;
}

main .bg-\[\#06a7e0\] h3,
main .bg-\[\#06a7e0\] h3.wp-block-heading {
  color: #25374f;
}

main .bg-\[\#166f97\] p a {
  font-weight: 700;
  border-bottom: none;
}
main .bg-\[\#166f97\] p a:hover {
  border-bottom: 1px solid #ffffff;
}

article iframe {
  width: 100%;
  margin-bottom: 20px;
  height: 450px;
}
article img {
  margin-bottom: 20px;
}
.google_map iframe {
  width: 100%;
  height: 550px;
}
/* END GLOBAL */

/* START SWIPER SLIDER BANNER */

.banner .swiper {
  width: 100%;
  /* height: 650px; */
}

.logo-division-slider .swiper {
  width: 100%;
  /* height: 350px; */
}

.division-slider .swiper {
  width: 100%;
  height: 100%;
}
.division-slider .swiper-button-prev,
.division-slider .swiper-button-next {
  top: 40% !important;
}

.banner .swiper .image,
.division-slider .swiper .image,
.logo-division-slider .swiper .image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}
.banner .swiper .swiper-slide,
.division-slider .swiper .swiper-slide {
  display: flex;
  /* align-items: center; */
}

.logo-division-slider .swiper-slide {
  align-content: center;
  justify-items: center;
}

.banner .swiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner .swiper .swiper-pagination-bullet-active {
  background: #00bbe3;
}
.banner .swiper-pagination-fraction,
.banner .swiper-pagination-custom,
.banner .swiper-horizontal > .swiper-pagination-bullets,
.banner .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 20px !important;
}
.banner .swiper-pagination-clickable .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
.banner .swiper-button-next::after,
.banner .swiper-button-prev::after,
.division-slider .swiper-button-next::after,
.division-slider .swiper-button-prev::after {
  display: none;
}
.banner .swiper-button-next {
  right: 0;
}
.banner .swiper-button-prev {
  left: 0;
}
.banner .banner-text:after {
  background: inherit;
  bottom: 0;
  content: "";
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  transform: skewY(-7.5deg);
  transform-origin: 100%;
  z-index: -1;
}
.wp-block-buttons {
  display: inline-block;
}
.wp-block-button {
  background-color: #06a7e0;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 100px;
}
.wp-block-button:hover {
  display: inline-block;
  background-color: #72bbe8;
  color: #ffffff;
}
@media only screen and (max-width: 575px) {
  .banner .swiper-button-next,
  .banner .swiper-button-prev {
    display: none;
  }
  .google_map iframe {
    width: 100%;
    height: 350px;
  }
}
/* END SWIPER SLIDER BANNER */
