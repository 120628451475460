@charset "UTF-8";

/* 1.css */

img:is([sizes="auto" i], [sizes^="auto," i]) {
  contain-intrinsic-size: 3000px 1500px;
}

/* 2.css */

.wp-block-site-logo {
  box-sizing: border-box;
  line-height: 0;
}
.wp-block-site-logo a {
  display: inline-block;
  line-height: 0;
}
.wp-block-site-logo.is-default-size img {
  height: auto;
  width: 120px;
}
.wp-block-site-logo img {
  height: auto;
  max-width: 100%;
}
.wp-block-site-logo a,
.wp-block-site-logo img {
  border-radius: inherit;
}
.wp-block-site-logo.aligncenter {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
:root :where(.wp-block-site-logo.is-style-rounded) {
  border-radius: 9999px;
}

/* 3.css */

.wp-block-site-title {
  box-sizing: border-box;
}
.wp-block-site-title :where(a) {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-decoration: inherit;
}

/* 4.css */

.wp-block-group {
  box-sizing: border-box;
}
:where(.wp-block-group.wp-block-group-is-layout-constrained) {
  position: relative;
}

/* 5.css */

.wp-block-navigation .wp-block-page-list {
  align-items: var(--navigation-layout-align, initial);
  background-color: inherit;
  display: flex;
  flex-direction: var(--navigation-layout-direction, initial);
  flex-wrap: var(--navigation-layout-wrap, wrap);
  justify-content: var(--navigation-layout-justify, initial);
}
.wp-block-navigation .wp-block-navigation-item {
  background-color: inherit;
}

/* 6.css */

.wp-block-post-featured-image {
  margin-left: 0;
  margin-right: 0;
}
.wp-block-post-featured-image a {
  display: block;
  height: 100%;
}
.wp-block-post-featured-image :where(img) {
  box-sizing: border-box;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  width: 100%;
}
.wp-block-post-featured-image.alignfull img,
.wp-block-post-featured-image.alignwide img {
  width: 100%;
}
.wp-block-post-featured-image
  .wp-block-post-featured-image__overlay.has-background-dim {
  background-color: #000;
  inset: 0;
  position: absolute;
}
.wp-block-post-featured-image {
  position: relative;
}
.wp-block-post-featured-image
  .wp-block-post-featured-image__overlay.has-background-gradient {
  background-color: initial;
}
.wp-block-post-featured-image
  .wp-block-post-featured-image__overlay.has-background-dim-0 {
  opacity: 0;
}
.wp-block-post-featured-image
  .wp-block-post-featured-image__overlay.has-background-dim-10 {
  opacity: 0.1;
}
.wp-block-post-featured-image
  .wp-block-post-featured-image__overlay.has-background-dim-20 {
  opacity: 0.2;
}
.wp-block-post-featured-image
  .wp-block-post-featured-image__overlay.has-background-dim-30 {
  opacity: 0.3;
}
.wp-block-post-featured-image
  .wp-block-post-featured-image__overlay.has-background-dim-40 {
  opacity: 0.4;
}
.wp-block-post-featured-image
  .wp-block-post-featured-image__overlay.has-background-dim-50 {
  opacity: 0.5;
}
.wp-block-post-featured-image
  .wp-block-post-featured-image__overlay.has-background-dim-60 {
  opacity: 0.6;
}
.wp-block-post-featured-image
  .wp-block-post-featured-image__overlay.has-background-dim-70 {
  opacity: 0.7;
}
.wp-block-post-featured-image
  .wp-block-post-featured-image__overlay.has-background-dim-80 {
  opacity: 0.8;
}
.wp-block-post-featured-image
  .wp-block-post-featured-image__overlay.has-background-dim-90 {
  opacity: 0.9;
}
.wp-block-post-featured-image
  .wp-block-post-featured-image__overlay.has-background-dim-100 {
  opacity: 1;
}
.wp-block-post-featured-image:where(.alignleft, .alignright) {
  width: 100%;
}

/* 7.css */

.wp-block-post-title {
  box-sizing: border-box;
  word-break: break-word;
}
.wp-block-post-title :where(a) {
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-decoration: inherit;
}

/* 8.css */

.wp-block-post-date {
  box-sizing: border-box;
}

/* 9.css */

.is-small-text {
  font-size: 0.875em;
}
.is-regular-text {
  font-size: 1em;
}
.is-large-text {
  font-size: 2.25em;
}
.is-larger-text {
  font-size: 3em;
}
.has-drop-cap:not(:focus):first-letter {
  float: left;
  font-size: 8.4em;
  font-style: normal;
  font-weight: 100;
  line-height: 0.68;
  margin: 0.05em 0.1em 0 0;
  text-transform: uppercase;
}
body.rtl .has-drop-cap:not(:focus):first-letter {
  float: none;
  margin-left: 0.1em;
}
p.has-drop-cap.has-background {
  overflow: hidden;
}
:root :where(p.has-background) {
  padding: 1.25em 2.375em;
}
:where(p.has-text-color:not(.has-link-color)) a {
  color: inherit;
}
p.has-text-align-left[style*="writing-mode:vertical-lr"],
p.has-text-align-right[style*="writing-mode:vertical-rl"] {
  rotate: 180deg;
}

/* 10.css */

.wp-block-post-author-name {
  box-sizing: border-box;
}

/* 11.css */

.wp-block-post-terms {
  box-sizing: border-box;
}
.wp-block-post-terms .wp-block-post-terms__separator {
  white-space: pre-wrap;
}

.is-style-pill a,
.is-style-pill span:not([class], [data-rich-text-placeholder]) {
  display: inline-block;
  background-color: var(--wp--preset--color--base-2);
  padding: 0.375rem 0.875rem;
  border-radius: var(--wp--preset--spacing--20);
}

.is-style-pill a:hover {
  background-color: var(--wp--preset--color--contrast-3);
}

/* 12.css */

.wp-block-image a {
  display: inline-block;
}
.wp-block-image img {
  box-sizing: border-box;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}
@media (prefers-reduced-motion: no-preference) {
  .wp-block-image img.hide {
    visibility: hidden;
  }
  .wp-block-image img.show {
    animation: show-content-image 0.4s;
  }
}
.wp-block-image[style*="border-radius"] img,
.wp-block-image[style*="border-radius"] > a {
  border-radius: inherit;
}
.wp-block-image.has-custom-border img {
  box-sizing: border-box;
}
.wp-block-image.aligncenter {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.wp-block-image.alignfull a,
.wp-block-image.alignwide a {
  width: 100%;
}
.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
  height: auto;
  width: 100%;
}
.wp-block-image .aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image.aligncenter,
.wp-block-image.alignleft,
.wp-block-image.alignright {
  display: table;
}
.wp-block-image .aligncenter > figcaption,
.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption,
.wp-block-image.aligncenter > figcaption,
.wp-block-image.alignleft > figcaption,
.wp-block-image.alignright > figcaption {
  caption-side: bottom;
  display: table-caption;
}
.wp-block-image .alignleft {
  float: left;
  margin: 0.5em 1em 0.5em 0;
}
.wp-block-image .alignright {
  float: right;
  margin: 0.5em 0 0.5em 1em;
}
.wp-block-image .aligncenter {
  margin-left: auto;
  margin-right: auto;
}
.wp-block-image :where(figcaption) {
  margin-bottom: 1em;
  margin-top: 0.5em;
}
.wp-block-image.is-style-circle-mask img {
  border-radius: 9999px;
}
@supports ((-webkit-mask-image: none) or (mask-image: none)) or
  (-webkit-mask-image: none) {
  .wp-block-image.is-style-circle-mask img {
    border-radius: 0;
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    mask-mode: alpha;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
  }
}
:root
  :where(
    .wp-block-image.is-style-rounded img,
    .wp-block-image .is-style-rounded img
  ) {
  border-radius: 9999px;
}
.wp-block-image figure {
  margin: 0;
}
.wp-lightbox-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.wp-lightbox-container img {
  cursor: zoom-in;
}
.wp-lightbox-container img:hover + button {
  opacity: 1;
}
.wp-lightbox-container button {
  align-items: center;
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  backdrop-filter: blur(16px) saturate(180%);
  background-color: #5a5a5a40;
  border: none;
  border-radius: 4px;
  cursor: zoom-in;
  display: flex;
  height: 20px;
  justify-content: center;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 16px;
  text-align: center;
  top: 16px;
  transition: opacity 0.2s ease;
  width: 20px;
  z-index: 100;
}
.wp-lightbox-container button:focus-visible {
  outline: 3px auto #5a5a5a40;
  outline: 3px auto -webkit-focus-ring-color;
  outline-offset: 3px;
}
.wp-lightbox-container button:hover {
  cursor: pointer;
  opacity: 1;
}
.wp-lightbox-container button:focus {
  opacity: 1;
}
.wp-lightbox-container button:focus,
.wp-lightbox-container button:hover,
.wp-lightbox-container button:not(:hover):not(:active):not(.has-background) {
  background-color: #5a5a5a40;
  border: none;
}
.wp-lightbox-overlay {
  box-sizing: border-box;
  cursor: zoom-out;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 100000;
}
.wp-lightbox-overlay .close-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 40px;
  min-width: 40px;
  padding: 0;
  position: absolute;
  right: calc(env(safe-area-inset-right) + 16px);
  top: calc(env(safe-area-inset-top) + 16px);
  z-index: 5000000;
}
.wp-lightbox-overlay .close-button:focus,
.wp-lightbox-overlay .close-button:hover,
.wp-lightbox-overlay
  .close-button:not(:hover):not(:active):not(.has-background) {
  background: none;
  border: none;
}
.wp-lightbox-overlay .lightbox-image-container {
  height: var(--wp--lightbox-container-height);
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: top left;
  width: var(--wp--lightbox-container-width);
  z-index: 9999999999;
}
.wp-lightbox-overlay .wp-block-image {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
  position: relative;
  transform-origin: 0 0;
  width: 100%;
  z-index: 3000000;
}
.wp-lightbox-overlay .wp-block-image img {
  height: var(--wp--lightbox-image-height);
  min-height: var(--wp--lightbox-image-height);
  min-width: var(--wp--lightbox-image-width);
  width: var(--wp--lightbox-image-width);
}
.wp-lightbox-overlay .wp-block-image figcaption {
  display: none;
}
.wp-lightbox-overlay button {
  background: none;
  border: none;
}
.wp-lightbox-overlay .scrim {
  background-color: #fff;
  height: 100%;
  opacity: 0.9;
  position: absolute;
  width: 100%;
  z-index: 2000000;
}
.wp-lightbox-overlay.active {
  animation: turn-on-visibility 0.25s both;
  visibility: visible;
}
.wp-lightbox-overlay.active img {
  animation: turn-on-visibility 0.35s both;
}
.wp-lightbox-overlay.show-closing-animation:not(.active) {
  animation: turn-off-visibility 0.35s both;
}
.wp-lightbox-overlay.show-closing-animation:not(.active) img {
  animation: turn-off-visibility 0.25s both;
}
@media (prefers-reduced-motion: no-preference) {
  .wp-lightbox-overlay.zoom.active {
    animation: none;
    opacity: 1;
    visibility: visible;
  }
  .wp-lightbox-overlay.zoom.active .lightbox-image-container {
    animation: lightbox-zoom-in 0.4s;
  }
  .wp-lightbox-overlay.zoom.active .lightbox-image-container img {
    animation: none;
  }
  .wp-lightbox-overlay.zoom.active .scrim {
    animation: turn-on-visibility 0.4s forwards;
  }
  .wp-lightbox-overlay.zoom.show-closing-animation:not(.active) {
    animation: none;
  }
  .wp-lightbox-overlay.zoom.show-closing-animation:not(.active)
    .lightbox-image-container {
    animation: lightbox-zoom-out 0.4s;
  }
  .wp-lightbox-overlay.zoom.show-closing-animation:not(.active)
    .lightbox-image-container
    img {
    animation: none;
  }
  .wp-lightbox-overlay.zoom.show-closing-animation:not(.active) .scrim {
    animation: turn-off-visibility 0.4s forwards;
  }
}
@keyframes show-content-image {
  0% {
    visibility: hidden;
  }
  99% {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}
@keyframes turn-on-visibility {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes turn-off-visibility {
  0% {
    opacity: 1;
    visibility: visible;
  }
  99% {
    opacity: 0;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes lightbox-zoom-in {
  0% {
    transform: translate(
        calc(
          (-100vw + var(--wp--lightbox-scrollbar-width)) / 2 +
            var(--wp--lightbox-initial-left-position)
        ),
        calc(-50vh + var(--wp--lightbox-initial-top-position))
      )
      scale(var(--wp--lightbox-scale));
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes lightbox-zoom-out {
  0% {
    transform: translate(-50%, -50%) scale(1);
    visibility: visible;
  }
  99% {
    visibility: visible;
  }
  to {
    transform: translate(
        calc(
          (-100vw + var(--wp--lightbox-scrollbar-width)) / 2 +
            var(--wp--lightbox-initial-left-position)
        ),
        calc(-50vh + var(--wp--lightbox-initial-top-position))
      )
      scale(var(--wp--lightbox-scale));
    visibility: hidden;
  }
}

/* 13.css */
.blocks-gallery-grid:not(.has-nested-images),
.wp-block-gallery:not(.has-nested-images) {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 1em 1em 0;
  position: relative;
  width: calc(50% - 1em);
}
.blocks-gallery-grid:not(.has-nested-images)
  .blocks-gallery-image:nth-of-type(2n),
.blocks-gallery-grid:not(.has-nested-images)
  .blocks-gallery-item:nth-of-type(2n),
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item:nth-of-type(2n) {
  margin-right: 0;
}
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image figure,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item figure,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image figure,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item figure {
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  margin: 0;
}
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image img,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item img {
  display: block;
  height: auto;
  max-width: 100%;
  width: auto;
}
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image figcaption,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item figcaption,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image figcaption,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item figcaption {
  background: linear-gradient(0deg, #000000b3, #0000004d 70%, #0000);
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  font-size: 0.8em;
  margin: 0;
  max-height: 100%;
  overflow: auto;
  padding: 3em 0.77em 0.7em;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2;
}
.blocks-gallery-grid:not(.has-nested-images)
  .blocks-gallery-image
  figcaption
  img,
.blocks-gallery-grid:not(.has-nested-images)
  .blocks-gallery-item
  figcaption
  img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image figcaption img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item figcaption img {
  display: inline;
}
.blocks-gallery-grid:not(.has-nested-images) figcaption,
.wp-block-gallery:not(.has-nested-images) figcaption {
  flex-grow: 1;
}
.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-image a,
.blocks-gallery-grid:not(.has-nested-images).is-cropped
  .blocks-gallery-image
  img,
.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-item a,
.blocks-gallery-grid:not(.has-nested-images).is-cropped
  .blocks-gallery-item
  img,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-image a,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-image img,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-item a,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-item img {
  flex: 1;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-item,
.wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-image,
.wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-item {
  margin-right: 0;
  width: 100%;
}
@media (min-width: 600px) {
  .blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(33.33333% - 0.66667em);
  }
  .blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(25% - 0.75em);
  }
  .blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(20% - 0.8em);
  }
  .blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(16.66667% - 0.83333em);
  }
  .blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(14.28571% - 0.85714em);
  }
  .blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(12.5% - 0.875em);
  }
  .blocks-gallery-grid:not(.has-nested-images).columns-1
    .blocks-gallery-image:nth-of-type(1n),
  .blocks-gallery-grid:not(.has-nested-images).columns-1
    .blocks-gallery-item:nth-of-type(1n),
  .blocks-gallery-grid:not(.has-nested-images).columns-2
    .blocks-gallery-image:nth-of-type(2n),
  .blocks-gallery-grid:not(.has-nested-images).columns-2
    .blocks-gallery-item:nth-of-type(2n),
  .blocks-gallery-grid:not(.has-nested-images).columns-3
    .blocks-gallery-image:nth-of-type(3n),
  .blocks-gallery-grid:not(.has-nested-images).columns-3
    .blocks-gallery-item:nth-of-type(3n),
  .blocks-gallery-grid:not(.has-nested-images).columns-4
    .blocks-gallery-image:nth-of-type(4n),
  .blocks-gallery-grid:not(.has-nested-images).columns-4
    .blocks-gallery-item:nth-of-type(4n),
  .blocks-gallery-grid:not(.has-nested-images).columns-5
    .blocks-gallery-image:nth-of-type(5n),
  .blocks-gallery-grid:not(.has-nested-images).columns-5
    .blocks-gallery-item:nth-of-type(5n),
  .blocks-gallery-grid:not(.has-nested-images).columns-6
    .blocks-gallery-image:nth-of-type(6n),
  .blocks-gallery-grid:not(.has-nested-images).columns-6
    .blocks-gallery-item:nth-of-type(6n),
  .blocks-gallery-grid:not(.has-nested-images).columns-7
    .blocks-gallery-image:nth-of-type(7n),
  .blocks-gallery-grid:not(.has-nested-images).columns-7
    .blocks-gallery-item:nth-of-type(7n),
  .blocks-gallery-grid:not(.has-nested-images).columns-8
    .blocks-gallery-image:nth-of-type(8n),
  .blocks-gallery-grid:not(.has-nested-images).columns-8
    .blocks-gallery-item:nth-of-type(8n),
  .wp-block-gallery:not(.has-nested-images).columns-1
    .blocks-gallery-image:nth-of-type(1n),
  .wp-block-gallery:not(.has-nested-images).columns-1
    .blocks-gallery-item:nth-of-type(1n),
  .wp-block-gallery:not(.has-nested-images).columns-2
    .blocks-gallery-image:nth-of-type(2n),
  .wp-block-gallery:not(.has-nested-images).columns-2
    .blocks-gallery-item:nth-of-type(2n),
  .wp-block-gallery:not(.has-nested-images).columns-3
    .blocks-gallery-image:nth-of-type(3n),
  .wp-block-gallery:not(.has-nested-images).columns-3
    .blocks-gallery-item:nth-of-type(3n),
  .wp-block-gallery:not(.has-nested-images).columns-4
    .blocks-gallery-image:nth-of-type(4n),
  .wp-block-gallery:not(.has-nested-images).columns-4
    .blocks-gallery-item:nth-of-type(4n),
  .wp-block-gallery:not(.has-nested-images).columns-5
    .blocks-gallery-image:nth-of-type(5n),
  .wp-block-gallery:not(.has-nested-images).columns-5
    .blocks-gallery-item:nth-of-type(5n),
  .wp-block-gallery:not(.has-nested-images).columns-6
    .blocks-gallery-image:nth-of-type(6n),
  .wp-block-gallery:not(.has-nested-images).columns-6
    .blocks-gallery-item:nth-of-type(6n),
  .wp-block-gallery:not(.has-nested-images).columns-7
    .blocks-gallery-image:nth-of-type(7n),
  .wp-block-gallery:not(.has-nested-images).columns-7
    .blocks-gallery-item:nth-of-type(7n),
  .wp-block-gallery:not(.has-nested-images).columns-8
    .blocks-gallery-image:nth-of-type(8n),
  .wp-block-gallery:not(.has-nested-images).columns-8
    .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0;
  }
}
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image:last-child,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item:last-child,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image:last-child,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item:last-child {
  margin-right: 0;
}
.blocks-gallery-grid:not(.has-nested-images).alignleft,
.blocks-gallery-grid:not(.has-nested-images).alignright,
.wp-block-gallery:not(.has-nested-images).alignleft,
.wp-block-gallery:not(.has-nested-images).alignright {
  max-width: 420px;
  width: 100%;
}
.blocks-gallery-grid:not(.has-nested-images).aligncenter
  .blocks-gallery-item
  figure,
.wp-block-gallery:not(.has-nested-images).aligncenter
  .blocks-gallery-item
  figure {
  justify-content: center;
}
.wp-block-gallery:not(.is-cropped) .blocks-gallery-item {
  align-self: flex-start;
}
figure.wp-block-gallery.has-nested-images {
  align-items: normal;
}
.wp-block-gallery.has-nested-images
  figure.wp-block-image:not(#individual-image) {
  margin: 0;
  width: calc(50% - var(--wp--style--unstable-gallery-gap, 16px) / 2);
}
.wp-block-gallery.has-nested-images figure.wp-block-image {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  max-width: 100%;
  position: relative;
}
.wp-block-gallery.has-nested-images figure.wp-block-image > a,
.wp-block-gallery.has-nested-images figure.wp-block-image > div {
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
}
.wp-block-gallery.has-nested-images figure.wp-block-image img {
  display: block;
  height: auto;
  max-width: 100% !important;
  width: auto;
}
.wp-block-gallery.has-nested-images figure.wp-block-image figcaption,
.wp-block-gallery.has-nested-images
  figure.wp-block-image:has(figcaption):before {
  bottom: 0;
  left: 0;
  max-height: 100%;
  position: absolute;
  right: 0;
}
.wp-block-gallery.has-nested-images
  figure.wp-block-image:has(figcaption):before {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  content: "";
  height: 100%;
  -webkit-mask-image: linear-gradient(0deg, #000 20%, #0000);
  mask-image: linear-gradient(0deg, #000 20%, #0000);
  max-height: 40%;
}
.wp-block-gallery.has-nested-images figure.wp-block-image figcaption {
  background: linear-gradient(0deg, #0006, #0000);
  box-sizing: border-box;
  color: #fff;
  font-size: 13px;
  margin: 0;
  overflow: auto;
  padding: 1em;
  scrollbar-color: #0000 #0000;
  scrollbar-gutter: stable both-edges;
  scrollbar-width: thin;
  text-align: center;
  text-shadow: 0 0 1.5px #000;
  will-change: transform;
}
.wp-block-gallery.has-nested-images
  figure.wp-block-image
  figcaption::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}
.wp-block-gallery.has-nested-images
  figure.wp-block-image
  figcaption::-webkit-scrollbar-track {
  background-color: initial;
}
.wp-block-gallery.has-nested-images
  figure.wp-block-image
  figcaption::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: initial;
  border: 3px solid #0000;
  border-radius: 8px;
}
.wp-block-gallery.has-nested-images
  figure.wp-block-image
  figcaption:focus-within::-webkit-scrollbar-thumb,
.wp-block-gallery.has-nested-images
  figure.wp-block-image
  figcaption:focus::-webkit-scrollbar-thumb,
.wp-block-gallery.has-nested-images
  figure.wp-block-image
  figcaption:hover::-webkit-scrollbar-thumb {
  background-color: #fffc;
}
.wp-block-gallery.has-nested-images figure.wp-block-image figcaption:focus,
.wp-block-gallery.has-nested-images
  figure.wp-block-image
  figcaption:focus-within,
.wp-block-gallery.has-nested-images figure.wp-block-image figcaption:hover {
  scrollbar-color: #fffc #0000;
}
@media (hover: none) {
  .wp-block-gallery.has-nested-images figure.wp-block-image figcaption {
    scrollbar-color: #fffc #0000;
  }
}
.wp-block-gallery.has-nested-images figure.wp-block-image figcaption img {
  display: inline;
}
.wp-block-gallery.has-nested-images figure.wp-block-image figcaption a {
  color: inherit;
}
.wp-block-gallery.has-nested-images
  figure.wp-block-image.has-custom-border
  img {
  box-sizing: border-box;
}
.wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border > a,
.wp-block-gallery.has-nested-images
  figure.wp-block-image.has-custom-border
  > div,
.wp-block-gallery.has-nested-images figure.wp-block-image.is-style-rounded > a,
.wp-block-gallery.has-nested-images
  figure.wp-block-image.is-style-rounded
  > div {
  flex: 1 1 auto;
}
.wp-block-gallery.has-nested-images
  figure.wp-block-image.has-custom-border
  figcaption,
.wp-block-gallery.has-nested-images
  figure.wp-block-image.is-style-rounded
  figcaption {
  background: none;
  color: inherit;
  flex: initial;
  margin: 0;
  padding: 10px 10px 9px;
  position: relative;
  text-shadow: none;
}
.wp-block-gallery.has-nested-images
  figure.wp-block-image.has-custom-border:before,
.wp-block-gallery.has-nested-images
  figure.wp-block-image.is-style-rounded:before {
  content: none;
}
.wp-block-gallery.has-nested-images figcaption {
  flex-basis: 100%;
  flex-grow: 1;
  text-align: center;
}
.wp-block-gallery.has-nested-images:not(.is-cropped)
  figure.wp-block-image:not(#individual-image) {
  margin-bottom: auto;
  margin-top: 0;
}
.wp-block-gallery.has-nested-images.is-cropped
  figure.wp-block-image:not(#individual-image) {
  align-self: inherit;
}
.wp-block-gallery.has-nested-images.is-cropped
  figure.wp-block-image:not(#individual-image)
  > a,
.wp-block-gallery.has-nested-images.is-cropped
  figure.wp-block-image:not(#individual-image)
  > div:not(.components-drop-zone) {
  display: flex;
}
.wp-block-gallery.has-nested-images.is-cropped
  figure.wp-block-image:not(#individual-image)
  a,
.wp-block-gallery.has-nested-images.is-cropped
  figure.wp-block-image:not(#individual-image)
  img {
  flex: 1 0 0%;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.wp-block-gallery.has-nested-images.columns-1
  figure.wp-block-image:not(#individual-image) {
  width: 100%;
}
@media (min-width: 600px) {
  .wp-block-gallery.has-nested-images.columns-3
    figure.wp-block-image:not(#individual-image) {
    width: calc(
      33.33333% - var(--wp--style--unstable-gallery-gap, 16px) * 0.66667
    );
  }
  .wp-block-gallery.has-nested-images.columns-4
    figure.wp-block-image:not(#individual-image) {
    width: calc(25% - var(--wp--style--unstable-gallery-gap, 16px) * 0.75);
  }
  .wp-block-gallery.has-nested-images.columns-5
    figure.wp-block-image:not(#individual-image) {
    width: calc(20% - var(--wp--style--unstable-gallery-gap, 16px) * 0.8);
  }
  .wp-block-gallery.has-nested-images.columns-6
    figure.wp-block-image:not(#individual-image) {
    width: calc(
      16.66667% - var(--wp--style--unstable-gallery-gap, 16px) * 0.83333
    );
  }
  .wp-block-gallery.has-nested-images.columns-7
    figure.wp-block-image:not(#individual-image) {
    width: calc(
      14.28571% - var(--wp--style--unstable-gallery-gap, 16px) * 0.85714
    );
  }
  .wp-block-gallery.has-nested-images.columns-8
    figure.wp-block-image:not(#individual-image) {
    width: calc(12.5% - var(--wp--style--unstable-gallery-gap, 16px) * 0.875);
  }
  .wp-block-gallery.has-nested-images.columns-default
    figure.wp-block-image:not(#individual-image) {
    width: calc(
      33.33% - var(--wp--style--unstable-gallery-gap, 16px) * 0.66667
    );
  }
  .wp-block-gallery.has-nested-images.columns-default
    figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2),
  .wp-block-gallery.has-nested-images.columns-default
    figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2)
    ~ figure.wp-block-image:not(#individual-image) {
    width: calc(50% - var(--wp--style--unstable-gallery-gap, 16px) * 0.5);
  }
  .wp-block-gallery.has-nested-images.columns-default
    figure.wp-block-image:not(#individual-image):first-child:last-child {
    width: 100%;
  }
}
.wp-block-gallery.has-nested-images.alignleft,
.wp-block-gallery.has-nested-images.alignright {
  max-width: 420px;
  width: 100%;
}
.wp-block-gallery.has-nested-images.aligncenter {
  justify-content: center;
}

/* 14.css */

.wp-block-post-content {
  display: flow-root;
}

/* 15.css */

.wp-block-spacer {
  clear: both;
}

/* 16.css */

.wp-block-navigation {
  position: relative;
  --navigation-layout-justification-setting: flex-start;
  --navigation-layout-direction: row;
  --navigation-layout-wrap: wrap;
  --navigation-layout-justify: flex-start;
  --navigation-layout-align: center;
}
.wp-block-navigation ul {
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 0;
  padding-left: 0;
}
.wp-block-navigation ul,
.wp-block-navigation ul li {
  list-style: none;
  padding: 0;
}
.wp-block-navigation .wp-block-navigation-item {
  align-items: center;
  background-color: inherit;
  display: flex;
  position: relative;
}
.wp-block-navigation
  .wp-block-navigation-item
  .wp-block-navigation__submenu-container:empty {
  display: none;
}
.wp-block-navigation .wp-block-navigation-item__content {
  display: block;
}
.wp-block-navigation
  .wp-block-navigation-item__content.wp-block-navigation-item__content {
  color: inherit;
}
.wp-block-navigation.has-text-decoration-underline
  .wp-block-navigation-item__content,
.wp-block-navigation.has-text-decoration-underline
  .wp-block-navigation-item__content:active,
.wp-block-navigation.has-text-decoration-underline
  .wp-block-navigation-item__content:focus {
  text-decoration: underline;
}
.wp-block-navigation.has-text-decoration-line-through
  .wp-block-navigation-item__content,
.wp-block-navigation.has-text-decoration-line-through
  .wp-block-navigation-item__content:active,
.wp-block-navigation.has-text-decoration-line-through
  .wp-block-navigation-item__content:focus {
  text-decoration: line-through;
}
.wp-block-navigation :where(a),
.wp-block-navigation :where(a:active),
.wp-block-navigation :where(a:focus) {
  text-decoration: none;
}
.wp-block-navigation .wp-block-navigation__submenu-icon {
  align-self: center;
  background-color: inherit;
  border: none;
  color: currentColor;
  display: inline-block;
  font-size: inherit;
  height: 0.6em;
  line-height: 0;
  margin-left: 0.25em;
  padding: 0;
  width: 0.6em;
}
.wp-block-navigation .wp-block-navigation__submenu-icon svg {
  display: inline-block;
  stroke: currentColor;
  height: inherit;
  margin-top: 0.075em;
  width: inherit;
}
.wp-block-navigation.is-vertical {
  --navigation-layout-direction: column;
  --navigation-layout-justify: initial;
  --navigation-layout-align: flex-start;
}
.wp-block-navigation.no-wrap {
  --navigation-layout-wrap: nowrap;
}
.wp-block-navigation.items-justified-center {
  --navigation-layout-justification-setting: center;
  --navigation-layout-justify: center;
}
.wp-block-navigation.items-justified-center.is-vertical {
  --navigation-layout-align: center;
}
.wp-block-navigation.items-justified-right {
  --navigation-layout-justification-setting: flex-end;
  --navigation-layout-justify: flex-end;
}
.wp-block-navigation.items-justified-right.is-vertical {
  --navigation-layout-align: flex-end;
}
.wp-block-navigation.items-justified-space-between {
  --navigation-layout-justification-setting: space-between;
  --navigation-layout-justify: space-between;
}
.wp-block-navigation .has-child .wp-block-navigation__submenu-container {
  align-items: normal;
  background-color: inherit;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 0;
  left: -1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  transition: opacity 0.1s linear;
  visibility: hidden;
  width: 0;
  z-index: 2;
}
.wp-block-navigation
  .has-child
  .wp-block-navigation__submenu-container
  > .wp-block-navigation-item
  > .wp-block-navigation-item__content {
  display: flex;
  flex-grow: 1;
}
.wp-block-navigation
  .has-child
  .wp-block-navigation__submenu-container
  > .wp-block-navigation-item
  > .wp-block-navigation-item__content
  .wp-block-navigation__submenu-icon {
  margin-left: auto;
  margin-right: 0;
}
.wp-block-navigation
  .has-child
  .wp-block-navigation__submenu-container
  .wp-block-navigation-item__content {
  margin: 0;
}
@media (min-width: 782px) {
  .wp-block-navigation
    .has-child
    .wp-block-navigation__submenu-container
    .wp-block-navigation__submenu-container {
    left: 100%;
    top: -1px;
  }
  .wp-block-navigation
    .has-child
    .wp-block-navigation__submenu-container
    .wp-block-navigation__submenu-container:before {
    background: #0000;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: 100%;
    width: 0.5em;
  }
  .wp-block-navigation
    .has-child
    .wp-block-navigation__submenu-container
    .wp-block-navigation__submenu-icon {
    margin-right: 0.25em;
  }
  .wp-block-navigation
    .has-child
    .wp-block-navigation__submenu-container
    .wp-block-navigation__submenu-icon
    svg {
    transform: rotate(-90deg);
  }
}
.wp-block-navigation
  .has-child
  .wp-block-navigation-submenu__toggle[aria-expanded="true"]
  ~ .wp-block-navigation__submenu-container,
.wp-block-navigation
  .has-child:not(.open-on-click):hover
  > .wp-block-navigation__submenu-container,
.wp-block-navigation
  .has-child:not(.open-on-click):not(.open-on-hover-click):focus-within
  > .wp-block-navigation__submenu-container {
  height: auto;
  min-width: 200px;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  width: auto;
}
.wp-block-navigation.has-background
  .has-child
  .wp-block-navigation__submenu-container {
  left: 0;
  top: 100%;
}
@media (min-width: 782px) {
  .wp-block-navigation.has-background
    .has-child
    .wp-block-navigation__submenu-container
    .wp-block-navigation__submenu-container {
    left: 100%;
    top: 0;
  }
}
.wp-block-navigation-submenu {
  display: flex;
  position: relative;
}
.wp-block-navigation-submenu .wp-block-navigation__submenu-icon svg {
  stroke: currentColor;
}
button.wp-block-navigation-item__content {
  background-color: initial;
  border: none;
  color: currentColor;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-align: left;
  text-transform: inherit;
}
.wp-block-navigation-submenu__toggle {
  cursor: pointer;
}
.wp-block-navigation-item.open-on-click .wp-block-navigation-submenu__toggle {
  padding-left: 0;
  padding-right: 0.85em;
}
.wp-block-navigation-item.open-on-click
  .wp-block-navigation-submenu__toggle
  + .wp-block-navigation__submenu-icon {
  margin-left: -0.6em;
  pointer-events: none;
}
.wp-block-navigation-item.open-on-click
  button.wp-block-navigation-item__content:not(
    .wp-block-navigation-submenu__toggle
  ) {
  padding: 0;
}
.wp-block-navigation .wp-block-page-list,
.wp-block-navigation__container,
.wp-block-navigation__responsive-close,
.wp-block-navigation__responsive-container,
.wp-block-navigation__responsive-container-content,
.wp-block-navigation__responsive-dialog {
  gap: inherit;
}
:where(
    .wp-block-navigation.has-background
      .wp-block-navigation-item
      a:not(.wp-element-button)
  ),
:where(
    .wp-block-navigation.has-background
      .wp-block-navigation-submenu
      a:not(.wp-element-button)
  ) {
  padding: 0.5em 1em;
}
:where(
    .wp-block-navigation
      .wp-block-navigation__submenu-container
      .wp-block-navigation-item
      a:not(.wp-element-button)
  ),
:where(
    .wp-block-navigation
      .wp-block-navigation__submenu-container
      .wp-block-navigation-submenu
      a:not(.wp-element-button)
  ),
:where(
    .wp-block-navigation
      .wp-block-navigation__submenu-container
      .wp-block-navigation-submenu
      button.wp-block-navigation-item__content
  ),
:where(
    .wp-block-navigation
      .wp-block-navigation__submenu-container
      .wp-block-pages-list__item
      button.wp-block-navigation-item__content
  ) {
  padding: 0.5em 1em;
}
.wp-block-navigation.items-justified-right
  .wp-block-navigation__container
  .has-child
  .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-right
  .wp-block-page-list
  > .has-child
  .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-space-between
  .wp-block-page-list
  > .has-child:last-child
  .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-space-between
  > .wp-block-navigation__container
  > .has-child:last-child
  .wp-block-navigation__submenu-container {
  left: auto;
  right: 0;
}
.wp-block-navigation.items-justified-right
  .wp-block-navigation__container
  .has-child
  .wp-block-navigation__submenu-container
  .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-right
  .wp-block-page-list
  > .has-child
  .wp-block-navigation__submenu-container
  .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-space-between
  .wp-block-page-list
  > .has-child:last-child
  .wp-block-navigation__submenu-container
  .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-space-between
  > .wp-block-navigation__container
  > .has-child:last-child
  .wp-block-navigation__submenu-container
  .wp-block-navigation__submenu-container {
  left: -1px;
  right: -1px;
}
@media (min-width: 782px) {
  .wp-block-navigation.items-justified-right
    .wp-block-navigation__container
    .has-child
    .wp-block-navigation__submenu-container
    .wp-block-navigation__submenu-container,
  .wp-block-navigation.items-justified-right
    .wp-block-page-list
    > .has-child
    .wp-block-navigation__submenu-container
    .wp-block-navigation__submenu-container,
  .wp-block-navigation.items-justified-space-between
    .wp-block-page-list
    > .has-child:last-child
    .wp-block-navigation__submenu-container
    .wp-block-navigation__submenu-container,
  .wp-block-navigation.items-justified-space-between
    > .wp-block-navigation__container
    > .has-child:last-child
    .wp-block-navigation__submenu-container
    .wp-block-navigation__submenu-container {
    left: auto;
    right: 100%;
  }
}
.wp-block-navigation:not(.has-background)
  .wp-block-navigation__submenu-container {
  background-color: #fff;
  border: 1px solid #00000026;
}
.wp-block-navigation.has-background .wp-block-navigation__submenu-container {
  background-color: inherit;
}
.wp-block-navigation:not(.has-text-color)
  .wp-block-navigation__submenu-container {
  color: #000;
}
.wp-block-navigation__container {
  align-items: var(--navigation-layout-align, initial);
  display: flex;
  flex-direction: var(--navigation-layout-direction, initial);
  flex-wrap: var(--navigation-layout-wrap, wrap);
  justify-content: var(--navigation-layout-justify, initial);
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.wp-block-navigation__container .is-responsive {
  display: none;
}
.wp-block-navigation__container:only-child,
.wp-block-page-list:only-child {
  flex-grow: 1;
}
@keyframes overlay-menu__fade-in-animation {
  0% {
    opacity: 0;
    transform: translateY(0.5em);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.wp-block-navigation__responsive-container {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.wp-block-navigation__responsive-container :where(.wp-block-navigation-item a) {
  color: inherit;
}
.wp-block-navigation__responsive-container
  .wp-block-navigation__responsive-container-content {
  align-items: var(--navigation-layout-align, initial);
  display: flex;
  flex-direction: var(--navigation-layout-direction, initial);
  flex-wrap: var(--navigation-layout-wrap, wrap);
  justify-content: var(--navigation-layout-justify, initial);
}
.wp-block-navigation__responsive-container:not(.is-menu-open.is-menu-open) {
  background-color: inherit !important;
  color: inherit !important;
}
.wp-block-navigation__responsive-container.is-menu-open {
  animation: overlay-menu__fade-in-animation 0.1s ease-out;
  animation-fill-mode: forwards;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: clamp(1rem, var(--wp--style--root--padding-top), 20rem)
    clamp(1rem, var(--wp--style--root--padding-right), 20rem)
    clamp(1rem, var(--wp--style--root--padding-bottom), 20rem)
    clamp(1rem, var(--wp--style--root--padding-left), 20em);
  z-index: 100000;
}
@media (prefers-reduced-motion: reduce) {
  .wp-block-navigation__responsive-container.is-menu-open {
    animation-delay: 0s;
    animation-duration: 1ms;
  }
}
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__responsive-container-content {
  align-items: var(--navigation-layout-justification-setting, inherit);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: visible;
  padding-top: calc(2rem + 24px);
}
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__responsive-container-content,
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__responsive-container-content
  .wp-block-navigation__container,
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__responsive-container-content
  .wp-block-page-list {
  justify-content: flex-start;
}
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__responsive-container-content
  .wp-block-navigation__submenu-icon {
  display: none;
}
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__responsive-container-content
  .has-child
  .wp-block-navigation__submenu-container {
  border: none;
  height: auto;
  min-width: 200px;
  opacity: 1;
  overflow: initial;
  padding-left: 2rem;
  padding-right: 2rem;
  position: static;
  visibility: visible;
  width: auto;
}
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__responsive-container-content
  .wp-block-navigation__container,
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__responsive-container-content
  .wp-block-navigation__submenu-container {
  gap: inherit;
}
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__responsive-container-content
  .wp-block-navigation__submenu-container {
  padding-top: var(--wp--style--block-gap, 2em);
}
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__responsive-container-content
  .wp-block-navigation-item__content {
  padding: 0;
}
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__responsive-container-content
  .wp-block-navigation-item,
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__responsive-container-content
  .wp-block-navigation__container,
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__responsive-container-content
  .wp-block-page-list {
  align-items: var(--navigation-layout-justification-setting, initial);
  display: flex;
  flex-direction: column;
}
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation-item,
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation-item
  .wp-block-navigation__submenu-container,
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__container,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-page-list {
  background: #0000 !important;
  color: inherit !important;
}
.wp-block-navigation__responsive-container.is-menu-open
  .wp-block-navigation__submenu-container.wp-block-navigation__submenu-container.wp-block-navigation__submenu-container.wp-block-navigation__submenu-container {
  left: auto;
  right: auto;
}
@media (min-width: 600px) {
  .wp-block-navigation__responsive-container:not(.hidden-by-default):not(
      .is-menu-open
    ) {
    background-color: inherit;
    display: block;
    position: relative;
    width: 100%;
    z-index: auto;
  }
  .wp-block-navigation__responsive-container:not(.hidden-by-default):not(
      .is-menu-open
    )
    .wp-block-navigation__responsive-container-close {
    display: none;
  }
  .wp-block-navigation__responsive-container.is-menu-open
    .wp-block-navigation__submenu-container.wp-block-navigation__submenu-container.wp-block-navigation__submenu-container.wp-block-navigation__submenu-container {
    left: 0;
  }
}
.wp-block-navigation:not(.has-background)
  .wp-block-navigation__responsive-container.is-menu-open {
  background-color: #fff;
}
.wp-block-navigation:not(.has-text-color)
  .wp-block-navigation__responsive-container.is-menu-open {
  color: #000;
}
.wp-block-navigation__toggle_button_label {
  font-size: 1rem;
  font-weight: 700;
}
.wp-block-navigation__responsive-container-close,
.wp-block-navigation__responsive-container-open {
  background: #0000;
  border: none;
  color: currentColor;
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-transform: inherit;
  vertical-align: middle;
}
.wp-block-navigation__responsive-container-close svg,
.wp-block-navigation__responsive-container-open svg {
  fill: currentColor;
  display: block;
  height: 24px;
  pointer-events: none;
  width: 24px;
}
.wp-block-navigation__responsive-container-open {
  display: flex;
}
.wp-block-navigation__responsive-container-open.wp-block-navigation__responsive-container-open.wp-block-navigation__responsive-container-open {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
@media (min-width: 600px) {
  .wp-block-navigation__responsive-container-open:not(.always-shown) {
    display: none;
  }
}
.wp-block-navigation__responsive-container-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.wp-block-navigation__responsive-container-close.wp-block-navigation__responsive-container-close.wp-block-navigation__responsive-container-close {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
.wp-block-navigation__responsive-close {
  width: 100%;
}
.has-modal-open .wp-block-navigation__responsive-close {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--wp--style--global--wide-size, 100%);
}
.wp-block-navigation__responsive-close:focus {
  outline: none;
}
.is-menu-open .wp-block-navigation__responsive-close,
.is-menu-open .wp-block-navigation__responsive-container-content,
.is-menu-open .wp-block-navigation__responsive-dialog {
  box-sizing: border-box;
}
.wp-block-navigation__responsive-dialog {
  position: relative;
}
.has-modal-open
  .admin-bar
  .is-menu-open
  .wp-block-navigation__responsive-dialog {
  margin-top: 46px;
}
@media (min-width: 782px) {
  .has-modal-open
    .admin-bar
    .is-menu-open
    .wp-block-navigation__responsive-dialog {
    margin-top: 32px;
  }
}
html.has-modal-open {
  overflow: hidden;
}

/* 17.css */

.wp-block-separator {
  border: none;
  border-top: 2px solid;
}
:root :where(.wp-block-separator.is-style-dots) {
  height: auto;
  line-height: 1;
  text-align: center;
}
:root :where(.wp-block-separator.is-style-dots):before {
  color: currentColor;
  content: "···";
  font-family: serif;
  font-size: 1.5em;
  letter-spacing: 2em;
  padding-left: 2em;
}
.wp-block-separator.is-style-dots {
  background: none !important;
  border: none !important;
}

/* 18.css */

.wp-block-post-comments {
  box-sizing: border-box;
}
.wp-block-post-comments .alignleft {
  float: left;
}
.wp-block-post-comments .alignright {
  float: right;
}
.wp-block-post-comments .navigation:after {
  clear: both;
  content: "";
  display: table;
}
.wp-block-post-comments .commentlist {
  clear: both;
  list-style: none;
  margin: 0;
  padding: 0;
}
.wp-block-post-comments .commentlist .comment {
  min-height: 2.25em;
  padding-left: 3.25em;
}
.wp-block-post-comments .commentlist .comment p {
  font-size: 1em;
  line-height: 1.8;
  margin: 1em 0;
}
.wp-block-post-comments .commentlist .children {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wp-block-post-comments .comment-author {
  line-height: 1.5;
}
.wp-block-post-comments .comment-author .avatar {
  border-radius: 1.5em;
  display: block;
  float: left;
  height: 2.5em;
  margin-right: 0.75em;
  margin-top: 0.5em;
  width: 2.5em;
}
.wp-block-post-comments .comment-author cite {
  font-style: normal;
}
.wp-block-post-comments .comment-meta {
  font-size: 0.875em;
  line-height: 1.5;
}
.wp-block-post-comments .comment-meta b {
  font-weight: 400;
}
.wp-block-post-comments .comment-meta .comment-awaiting-moderation {
  display: block;
  margin-bottom: 1em;
  margin-top: 1em;
}
.wp-block-post-comments .comment-body .commentmetadata {
  font-size: 0.875em;
}
.wp-block-post-comments .comment-form-author label,
.wp-block-post-comments .comment-form-comment label,
.wp-block-post-comments .comment-form-email label,
.wp-block-post-comments .comment-form-url label {
  display: block;
  margin-bottom: 0.25em;
}
.wp-block-post-comments
  .comment-form
  input:not([type="submit"]):not([type="checkbox"]),
.wp-block-post-comments .comment-form textarea {
  box-sizing: border-box;
  display: block;
  width: 100%;
}
.wp-block-post-comments .comment-form-cookies-consent {
  display: flex;
  gap: 0.25em;
}
.wp-block-post-comments
  .comment-form-cookies-consent
  #wp-comment-cookies-consent {
  margin-top: 0.35em;
}
.wp-block-post-comments .comment-reply-title {
  margin-bottom: 0;
}
.wp-block-post-comments .comment-reply-title :where(small) {
  font-size: var(--wp--preset--font-size--medium, smaller);
  margin-left: 0.5em;
}
.wp-block-post-comments .reply {
  font-size: 0.875em;
  margin-bottom: 1.4em;
}
.wp-block-post-comments input:not([type="submit"]),
.wp-block-post-comments textarea {
  border: 1px solid #949494;
  font-family: inherit;
  font-size: 1em;
}
.wp-block-post-comments input:not([type="submit"]):not([type="checkbox"]),
.wp-block-post-comments textarea {
  padding: calc(0.667em + 2px);
}
:where(.wp-block-post-comments input[type="submit"]) {
  border: none;
}

/* 19.css */

.wp-block-post-navigation-link .wp-block-post-navigation-link__arrow-previous {
  display: inline-block;
  margin-right: 1ch;
}
.wp-block-post-navigation-link
  .wp-block-post-navigation-link__arrow-previous:not(.is-arrow-chevron) {
  transform: scaleX(1);
}
.wp-block-post-navigation-link .wp-block-post-navigation-link__arrow-next {
  display: inline-block;
  margin-left: 1ch;
}
.wp-block-post-navigation-link
  .wp-block-post-navigation-link__arrow-next:not(.is-arrow-chevron) {
  transform: scaleX(1);
}
.wp-block-post-navigation-link.has-text-align-left[style*="writing-mode: vertical-lr"],
.wp-block-post-navigation-link.has-text-align-right[style*="writing-mode: vertical-rl"] {
  rotate: 180deg;
}

/* 20.css */

.wp-block-site-tagline {
  box-sizing: border-box;
}

/* 21.css */

h1.has-background,
h2.has-background,
h3.has-background,
h4.has-background,
h5.has-background,
h6.has-background {
  padding: 1.25em 2.375em;
}
h1.has-text-align-left[style*="writing-mode"]:where([style*="vertical-lr"]),
h1.has-text-align-right[style*="writing-mode"]:where([style*="vertical-rl"]),
h2.has-text-align-left[style*="writing-mode"]:where([style*="vertical-lr"]),
h2.has-text-align-right[style*="writing-mode"]:where([style*="vertical-rl"]),
h3.has-text-align-left[style*="writing-mode"]:where([style*="vertical-lr"]),
h3.has-text-align-right[style*="writing-mode"]:where([style*="vertical-rl"]),
h4.has-text-align-left[style*="writing-mode"]:where([style*="vertical-lr"]),
h4.has-text-align-right[style*="writing-mode"]:where([style*="vertical-rl"]),
h5.has-text-align-left[style*="writing-mode"]:where([style*="vertical-lr"]),
h5.has-text-align-right[style*="writing-mode"]:where([style*="vertical-rl"]),
h6.has-text-align-left[style*="writing-mode"]:where([style*="vertical-lr"]),
h6.has-text-align-right[style*="writing-mode"]:where([style*="vertical-rl"]) {
  rotate: 180deg;
}

.is-style-asterisk:before {
  content: "";
  width: 1.5rem;
  height: 3rem;
  background: var(--wp--preset--color--contrast-2, currentColor);
  clip-path: path(
    "M11.93.684v8.039l5.633-5.633 1.216 1.23-5.66 5.66h8.04v1.737H13.2l5.701 5.701-1.23 1.23-5.742-5.742V21h-1.737v-8.094l-5.77 5.77-1.23-1.217 5.743-5.742H.842V9.98h8.162l-5.701-5.7 1.23-1.231 5.66 5.66V.684h1.737Z"
  );
  display: block;
}

/* Hide the asterisk if the heading has no content, to avoid using empty headings to display the asterisk only, which is an A11Y issue */
.is-style-asterisk:empty:before {
  content: none;
}

.is-style-asterisk:-moz-only-whitespace:before {
  content: none;
}

.is-style-asterisk.has-text-align-center:before {
  margin: 0 auto;
}

.is-style-asterisk.has-text-align-right:before {
  margin-left: auto;
}

.rtl .is-style-asterisk.has-text-align-left:before {
  margin-right: auto;
}

/* 22.css */

.wp-block-navigation .wp-block-navigation-item__label {
  overflow-wrap: break-word;
}
.wp-block-navigation .wp-block-navigation-item__description {
  display: none;
}
.link-ui-tools {
  border-top: 1px solid #f0f0f0;
  padding: 8px;
}
.link-ui-block-inserter {
  padding-top: 8px;
}
.link-ui-block-inserter__back {
  margin-left: 8px;
  text-transform: uppercase;
}

.is-style-arrow-link .wp-block-navigation-item__label:after {
  content: "\2197";
  padding-inline-start: 0.25rem;
  vertical-align: middle;
  text-decoration: none;
  display: inline-block;
}

/* 23.css */

.wp-block-columns {
  align-items: normal !important;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap !important;
}
@media (min-width: 782px) {
  .wp-block-columns {
    flex-wrap: nowrap !important;
  }
}
.wp-block-columns.are-vertically-aligned-top {
  align-items: flex-start;
}
.wp-block-columns.are-vertically-aligned-center {
  align-items: center;
}
.wp-block-columns.are-vertically-aligned-bottom {
  align-items: flex-end;
}
@media (max-width: 781px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 100% !important;
  }
}
@media (min-width: 782px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 0;
    flex-grow: 1;
  }
  .wp-block-columns:not(.is-not-stacked-on-mobile)
    > .wp-block-column[style*="flex-basis"] {
    flex-grow: 0;
  }
}
.wp-block-columns.is-not-stacked-on-mobile {
  flex-wrap: nowrap !important;
}
.wp-block-columns.is-not-stacked-on-mobile > .wp-block-column {
  flex-basis: 0;
  flex-grow: 1;
}
.wp-block-columns.is-not-stacked-on-mobile
  > .wp-block-column[style*="flex-basis"] {
  flex-grow: 0;
}
:where(.wp-block-columns) {
  margin-bottom: 1.75em;
}
:where(.wp-block-columns.has-background) {
  padding: 1.25em 2.375em;
}
.wp-block-column {
  flex-grow: 1;
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}
.wp-block-column.is-vertically-aligned-top {
  align-self: flex-start;
}
.wp-block-column.is-vertically-aligned-center {
  align-self: center;
}
.wp-block-column.is-vertically-aligned-bottom {
  align-self: flex-end;
}
.wp-block-column.is-vertically-aligned-stretch {
  align-self: stretch;
}
.wp-block-column.is-vertically-aligned-bottom,
.wp-block-column.is-vertically-aligned-center,
.wp-block-column.is-vertically-aligned-top {
  width: 100%;
}

/* 24.css */

img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

/* 25.css */
:root {
  --wp-admin-theme-color: #007cba;
  --wp-admin-theme-color--rgb: 0, 124, 186;
  --wp-admin-theme-color-darker-10: #006ba1;
  --wp-admin-theme-color-darker-10--rgb: 0, 107, 161;
  --wp-admin-theme-color-darker-20: #005a87;
  --wp-admin-theme-color-darker-20--rgb: 0, 90, 135;
  --wp-admin-border-width-focus: 2px;
  --wp-block-synced-color: #7a00df;
  --wp-block-synced-color--rgb: 122, 0, 223;
  --wp-bound-block-color: var(--wp-block-synced-color);
}
@media (min-resolution: 192dpi) {
  :root {
    --wp-admin-border-width-focus: 1.5px;
  }
}
.wp-element-button {
  cursor: pointer;
}
:root {
  --wp--preset--font-size--normal: 16px;
  --wp--preset--font-size--huge: 42px;
}
:root .has-very-light-gray-background-color {
  background-color: #eee;
}
:root .has-very-dark-gray-background-color {
  background-color: #313131;
}
:root .has-very-light-gray-color {
  color: #eee;
}
:root .has-very-dark-gray-color {
  color: #313131;
}
:root .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
  background: linear-gradient(135deg, #00d084, #0693e3);
}
:root .has-purple-crush-gradient-background {
  background: linear-gradient(135deg, #34e2e4, #4721fb 50%, #ab1dfe);
}
:root .has-hazy-dawn-gradient-background {
  background: linear-gradient(135deg, #faaca8, #dad0ec);
}
:root .has-subdued-olive-gradient-background {
  background: linear-gradient(135deg, #fafae1, #67a671);
}
:root .has-atomic-cream-gradient-background {
  background: linear-gradient(135deg, #fdd79a, #004a59);
}
:root .has-nightshade-gradient-background {
  background: linear-gradient(135deg, #330968, #31cdcf);
}
:root .has-midnight-gradient-background {
  background: linear-gradient(135deg, #020381, #2874fc);
}
.has-regular-font-size {
  font-size: 1em;
}
.has-larger-font-size {
  font-size: 2.625em;
}
.has-normal-font-size {
  font-size: var(--wp--preset--font-size--normal);
}
.has-huge-font-size {
  font-size: var(--wp--preset--font-size--huge);
}
.has-text-align-center {
  text-align: center;
}
.has-text-align-left {
  text-align: left;
}
.has-text-align-right {
  text-align: right;
}
#end-resizable-editor-section {
  display: none;
}
.aligncenter {
  clear: both;
}
.items-justified-left {
  justify-content: flex-start;
}
.items-justified-center {
  justify-content: center;
}
.items-justified-right {
  justify-content: flex-end;
}
.items-justified-space-between {
  justify-content: space-between;
}
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}
.screen-reader-text:focus {
  background-color: #ddd;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}
html :where(.has-border-color) {
  border-style: solid;
}
html :where([style*="border-top-color"]) {
  border-top-style: solid;
}
html :where([style*="border-right-color"]) {
  border-right-style: solid;
}
html :where([style*="border-bottom-color"]) {
  border-bottom-style: solid;
}
html :where([style*="border-left-color"]) {
  border-left-style: solid;
}
html :where([style*="border-width"]) {
  border-style: solid;
}
html :where([style*="border-top-width"]) {
  border-top-style: solid;
}
html :where([style*="border-right-width"]) {
  border-right-style: solid;
}
html :where([style*="border-bottom-width"]) {
  border-bottom-style: solid;
}
html :where([style*="border-left-width"]) {
  border-left-style: solid;
}
html :where(img[class*="wp-image-"]) {
  height: auto;
  max-width: 100%;
}
:where(figure) {
  margin: 0 0 1em;
}
html :where(.is-position-sticky) {
  --wp-admin--admin-bar--position-offset: var(
    --wp-admin--admin-bar--height,
    0px
  );
}
@media screen and (max-width: 600px) {
  html :where(.is-position-sticky) {
    --wp-admin--admin-bar--position-offset: 0px;
  }
}

/* 26.css */
:where(body) {
  margin: 0;
}
.wp-site-blocks {
  padding-top: var(--wp--style--root--padding-top);
  padding-bottom: var(--wp--style--root--padding-bottom);
}
.has-global-padding {
  padding-right: var(--wp--style--root--padding-right);
  padding-left: var(--wp--style--root--padding-left);
}
.has-global-padding > .alignfull {
  margin-right: calc(var(--wp--style--root--padding-right) * -1);
  margin-left: calc(var(--wp--style--root--padding-left) * -1);
}
.has-global-padding
  :where(
    :not(.alignfull.is-layout-flow)
      > .has-global-padding:not(.wp-block-block, .alignfull)
  ) {
  padding-right: 0;
  padding-left: 0;
}
.has-global-padding
  :where(
    :not(.alignfull.is-layout-flow)
      > .has-global-padding:not(.wp-block-block, .alignfull)
  )
  > .alignfull {
  margin-left: 0;
  margin-right: 0;
}
.wp-site-blocks > .alignleft {
  float: left;
  margin-right: 2em;
}
.wp-site-blocks > .alignright {
  float: right;
  margin-left: 2em;
}
.wp-site-blocks > .aligncenter {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
:where(.wp-site-blocks) > * {
  margin-block-start: 1.2rem;
  margin-block-end: 0;
}
:where(.wp-site-blocks) > :first-child {
  margin-block-start: 0;
}
:where(.wp-site-blocks) > :last-child {
  margin-block-end: 0;
}
:root {
  --wp--style--block-gap: 1.2rem;
}
:root :where(.is-layout-flow) > :first-child {
  margin-block-start: 0;
}
:root :where(.is-layout-flow) > :last-child {
  margin-block-end: 0;
}
:root :where(.is-layout-flow) > * {
  margin-block-start: 1.2rem;
  margin-block-end: 0;
}
:root :where(.is-layout-constrained) > :first-child {
  margin-block-start: 0;
}
:root :where(.is-layout-constrained) > :last-child {
  margin-block-end: 0;
}
:root :where(.is-layout-constrained) > * {
  margin-block-start: 1.2rem;
  margin-block-end: 0;
}
:root :where(.is-layout-flex) {
  gap: 1.2rem;
}
:root :where(.is-layout-grid) {
  gap: 1.2rem;
}
.is-layout-flow > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}
.is-layout-flow > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}
.is-layout-flow > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}
.is-layout-constrained > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}
.is-layout-constrained > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}
.is-layout-constrained > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}
.is-layout-constrained
  > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  max-width: var(--wp--style--global--content-size);
  margin-left: auto !important;
  margin-right: auto !important;
}
.is-layout-constrained > .alignwide {
  max-width: var(--wp--style--global--wide-size);
}
body .is-layout-flex {
  display: flex;
}
.is-layout-flex {
  flex-wrap: wrap;
  align-items: center;
}
.is-layout-flex > :is(*, div) {
  margin: 0;
}
body .is-layout-grid {
  display: grid;
}
.is-layout-grid > :is(*, div) {
  margin: 0;
}

:root :where(.wp-element-button, .wp-block-button__link) {
  background-color: var(--wp--preset--color--contrast);
  border-radius: 0.33rem;
  border-color: var(--wp--preset--color--contrast);
  border-width: 0;
  color: var(--wp--preset--color--base);
  font-family: inherit;
  font-size: var(--wp--preset--font-size--small);
  font-style: normal;
  font-weight: 500;
  line-height: inherit;
  padding-top: 0.6rem;
  padding-right: 1rem;
  padding-bottom: 0.6rem;
  padding-left: 1rem;
  text-decoration: none;
}
:root :where(.wp-element-button:hover, .wp-block-button__link:hover) {
  background-color: var(--wp--preset--color--contrast-2);
  border-color: var(--wp--preset--color--contrast-2);
  color: var(--wp--preset--color--base);
}
:root :where(.wp-element-button:focus, .wp-block-button__link:focus) {
  background-color: var(--wp--preset--color--contrast-2);
  border-color: var(--wp--preset--color--contrast-2);
  color: var(--wp--preset--color--base);
  outline-color: var(--wp--preset--color--contrast);
  outline-offset: 2px;
}
:root :where(.wp-element-button:active, .wp-block-button__link:active) {
  background-color: var(--wp--preset--color--contrast);
  color: var(--wp--preset--color--base);
}
:root
  :where(
    .wp-element-caption,
    .wp-block-audio figcaption,
    .wp-block-embed figcaption,
    .wp-block-gallery figcaption,
    .wp-block-image figcaption,
    .wp-block-table figcaption,
    .wp-block-video figcaption
  ) {
  color: var(--wp--preset--color--contrast-2);
  font-family: var(--wp--preset--font-family--body);
  font-size: 0.8rem;
}
.has-black-color {
  color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-color {
  color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-color {
  color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-color {
  color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-color {
  color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-color {
  color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-color {
  color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-color {
  color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-color {
  color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-color {
  color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-color {
  color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-color {
  color: var(--wp--preset--color--vivid-purple) !important;
}
.has-base-color {
  color: var(--wp--preset--color--base) !important;
}
.has-base-2-color {
  color: var(--wp--preset--color--base-2) !important;
}
.has-contrast-color {
  color: var(--wp--preset--color--contrast) !important;
}
.has-contrast-2-color {
  color: var(--wp--preset--color--contrast-2) !important;
}
.has-contrast-3-color {
  color: var(--wp--preset--color--contrast-3) !important;
}
.has-accent-color {
  color: var(--wp--preset--color--accent) !important;
}
.has-accent-2-color {
  color: var(--wp--preset--color--accent-2) !important;
}
.has-accent-3-color {
  color: var(--wp--preset--color--accent-3) !important;
}
.has-accent-4-color {
  color: var(--wp--preset--color--accent-4) !important;
}
.has-accent-5-color {
  color: var(--wp--preset--color--accent-5) !important;
}
.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-background-color {
  background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-background-color {
  background-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-background-color {
  background-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-background-color {
  background-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-background-color {
  background-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-background-color {
  background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-background-color {
  background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-background-color {
  background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-background-color {
  background-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-base-background-color {
  background-color: var(--wp--preset--color--base) !important;
}
.has-base-2-background-color {
  background-color: var(--wp--preset--color--base-2) !important;
}
.has-contrast-background-color {
  background-color: var(--wp--preset--color--contrast) !important;
}
.has-contrast-2-background-color {
  background-color: var(--wp--preset--color--contrast-2) !important;
}
.has-contrast-3-background-color {
  background-color: var(--wp--preset--color--contrast-3) !important;
}
.has-accent-background-color {
  background-color: var(--wp--preset--color--accent) !important;
}
.has-accent-2-background-color {
  background-color: var(--wp--preset--color--accent-2) !important;
}
.has-accent-3-background-color {
  background-color: var(--wp--preset--color--accent-3) !important;
}
.has-accent-4-background-color {
  background-color: var(--wp--preset--color--accent-4) !important;
}
.has-accent-5-background-color {
  background-color: var(--wp--preset--color--accent-5) !important;
}
.has-black-border-color {
  border-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-border-color {
  border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-border-color {
  border-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-border-color {
  border-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-border-color {
  border-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-border-color {
  border-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-border-color {
  border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-border-color {
  border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-border-color {
  border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-border-color {
  border-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-base-border-color {
  border-color: var(--wp--preset--color--base) !important;
}
.has-base-2-border-color {
  border-color: var(--wp--preset--color--base-2) !important;
}
.has-contrast-border-color {
  border-color: var(--wp--preset--color--contrast) !important;
}
.has-contrast-2-border-color {
  border-color: var(--wp--preset--color--contrast-2) !important;
}
.has-contrast-3-border-color {
  border-color: var(--wp--preset--color--contrast-3) !important;
}
.has-accent-border-color {
  border-color: var(--wp--preset--color--accent) !important;
}
.has-accent-2-border-color {
  border-color: var(--wp--preset--color--accent-2) !important;
}
.has-accent-3-border-color {
  border-color: var(--wp--preset--color--accent-3) !important;
}
.has-accent-4-border-color {
  border-color: var(--wp--preset--color--accent-4) !important;
}
.has-accent-5-border-color {
  border-color: var(--wp--preset--color--accent-5) !important;
}
.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: var(
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple
  ) !important;
}
.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: var(
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan
  ) !important;
}
.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: var(
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange
  ) !important;
}
.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: var(
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red
  ) !important;
}
.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: var(
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray
  ) !important;
}
.has-cool-to-warm-spectrum-gradient-background {
  background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}
.has-blush-light-purple-gradient-background {
  background: var(--wp--preset--gradient--blush-light-purple) !important;
}
.has-blush-bordeaux-gradient-background {
  background: var(--wp--preset--gradient--blush-bordeaux) !important;
}
.has-luminous-dusk-gradient-background {
  background: var(--wp--preset--gradient--luminous-dusk) !important;
}
.has-pale-ocean-gradient-background {
  background: var(--wp--preset--gradient--pale-ocean) !important;
}
.has-electric-grass-gradient-background {
  background: var(--wp--preset--gradient--electric-grass) !important;
}
.has-midnight-gradient-background {
  background: var(--wp--preset--gradient--midnight) !important;
}
.has-gradient-1-gradient-background {
  background: var(--wp--preset--gradient--gradient-1) !important;
}
.has-gradient-2-gradient-background {
  background: var(--wp--preset--gradient--gradient-2) !important;
}
.has-gradient-3-gradient-background {
  background: var(--wp--preset--gradient--gradient-3) !important;
}
.has-gradient-4-gradient-background {
  background: var(--wp--preset--gradient--gradient-4) !important;
}
.has-gradient-5-gradient-background {
  background: var(--wp--preset--gradient--gradient-5) !important;
}
.has-gradient-6-gradient-background {
  background: var(--wp--preset--gradient--gradient-6) !important;
}
.has-gradient-7-gradient-background {
  background: var(--wp--preset--gradient--gradient-7) !important;
}
.has-gradient-8-gradient-background {
  background: var(--wp--preset--gradient--gradient-8) !important;
}
.has-gradient-9-gradient-background {
  background: var(--wp--preset--gradient--gradient-9) !important;
}
.has-gradient-10-gradient-background {
  background: var(--wp--preset--gradient--gradient-10) !important;
}
.has-gradient-11-gradient-background {
  background: var(--wp--preset--gradient--gradient-11) !important;
}
.has-gradient-12-gradient-background {
  background: var(--wp--preset--gradient--gradient-12) !important;
}
.has-small-font-size {
  font-size: var(--wp--preset--font-size--small) !important;
}
.has-medium-font-size {
  font-size: var(--wp--preset--font-size--medium) !important;
}
.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}
.has-x-large-font-size {
  font-size: var(--wp--preset--font-size--x-large) !important;
}
.has-xx-large-font-size {
  font-size: var(--wp--preset--font-size--xx-large) !important;
}
.has-body-font-family {
  font-family: var(--wp--preset--font-family--body) !important;
}
.has-heading-font-family {
  font-family: var(--wp--preset--font-family--heading) !important;
}
.has-system-sans-serif-font-family {
  font-family: var(--wp--preset--font-family--system-sans-serif) !important;
}
.has-system-serif-font-family {
  font-family: var(--wp--preset--font-family--system-serif) !important;
}
:where(.wp-site-blocks *:focus) {
  outline-width: 2px;
  outline-style: solid;
}
:root :where(.wp-block-gallery) {
  margin-bottom: var(--wp--preset--spacing--50);
}
:root :where(.wp-block-navigation) {
  font-weight: 500;
}
:root :where(.wp-block-navigation a:where(:not(.wp-element-button))) {
  text-decoration: none;
}
:root :where(.wp-block-navigation a:where(:not(.wp-element-button)):hover) {
  text-decoration: underline;
}
:root :where(.wp-block-post-author-name) {
  font-size: var(--wp--preset--font-size--small);
}
:root :where(.wp-block-post-author-name a:where(:not(.wp-element-button))) {
  text-decoration: none;
}
:root
  :where(.wp-block-post-author-name a:where(:not(.wp-element-button)):hover) {
  text-decoration: underline;
}
:root :where(.wp-block-post-date) {
  color: var(--wp--preset--color--contrast-2);
  font-size: var(--wp--preset--font-size--small);
}
:root :where(.wp-block-post-date a:where(:not(.wp-element-button))) {
  color: var(--wp--preset--color--contrast-2);
  text-decoration: none;
}
:root :where(.wp-block-post-date a:where(:not(.wp-element-button)):hover) {
  text-decoration: underline;
}
:root
  :where(
    .wp-block-post-featured-image img,
    .wp-block-post-featured-image .block-editor-media-placeholder,
    .wp-block-post-featured-image .wp-block-post-featured-image__overlay
  ) {
  border-radius: var(--wp--preset--spacing--20);
}
:root :where(.wp-block-post-terms) {
  font-size: var(--wp--preset--font-size--small);
}
:root :where(.wp-block-post-terms .wp-block-post-terms__prefix) {
  color: var(--wp--preset--color--contrast-2);
}
:root :where(.wp-block-post-terms a:where(:not(.wp-element-button))) {
  text-decoration: none;
}
:root :where(.wp-block-post-terms a:where(:not(.wp-element-button)):hover) {
  text-decoration: underline;
}
:root :where(.wp-block-post-title a:where(:not(.wp-element-button))) {
  text-decoration: none;
}
:root :where(.wp-block-post-title a:where(:not(.wp-element-button)):hover) {
  text-decoration: underline;
}
:root :where(.wp-block-separator) {
  border-color: currentColor;
  border-width: 0 0 1px 0;
  border-style: solid;
  color: #dddddd;
  margin: 25px 0;
}
:root :where(.wp-block-separator) {
}
:root
  :where(
    .wp-block-separator:not(.is-style-wide):not(.is-style-dots):not(
        .alignwide
      ):not(.alignfull)
  ) {
  width: var(--wp--preset--spacing--60);
}
:root :where(.wp-block-site-tagline) {
  color: var(--wp--preset--color--contrast-2);
  font-size: var(--wp--preset--font-size--small);
}
:root :where(.wp-block-site-title) {
  font-family: var(--wp--preset--font-family--body);
  font-size: clamp(0.875rem, 0.875rem + ((1vw - 0.2rem) * 0.542), 1.2rem);
  font-style: normal;
  font-weight: 600;
}
:root :where(.wp-block-site-title a:where(:not(.wp-element-button))) {
  text-decoration: none;
}
:root :where(.wp-block-site-title a:where(:not(.wp-element-button)):hover) {
  text-decoration: none;
}

/* 27.css */

.wp-container-core-group-is-layout-1 > * {
  margin-block-start: 0;
  margin-block-end: 0;
}
.wp-container-core-group-is-layout-1 > * + * {
  margin-block-start: 0px;
  margin-block-end: 0;
}
.wp-container-core-group-is-layout-2 {
  gap: var(--wp--preset--spacing--20);
}
.wp-container-core-navigation-is-layout-1 {
  gap: var(--wp--preset--spacing--20);
  justify-content: flex-end;
}
.wp-container-core-group-is-layout-3 {
  justify-content: flex-start;
}
.wp-container-core-group-is-layout-4 {
  justify-content: space-between;
}
.wp-container-core-group-is-layout-6 {
  gap: 0.3em;
  justify-content: flex-start;
}
.wp-container-core-group-is-layout-8 {
  gap: var(--wp--preset--spacing--10);
  flex-direction: column;
  align-items: stretch;
}
.wp-block-gallery.wp-block-gallery-1,
.wp-block-gallery.wp-block-gallery-2 {
  --wp--style--unstable-gallery-gap: var(
    --wp--style--gallery-gap-default,
    var(--gallery-block--gutter-size, var(--wp--style--block-gap, 0.5em))
  );
  gap: var(
    --wp--style--gallery-gap-default,
    var(--gallery-block--gutter-size, var(--wp--style--block-gap, 0.5em))
  );
}
.wp-container-core-group-is-layout-10 {
  flex-wrap: nowrap;
  justify-content: space-between;
}
.wp-container-core-group-is-layout-14 {
  flex-direction: column;
  align-items: flex-start;
}
.wp-container-core-navigation-is-layout-2 {
  gap: var(--wp--preset--spacing--10);
  flex-direction: column;
  align-items: flex-start;
}
.wp-container-core-group-is-layout-15 {
  gap: var(--wp--preset--spacing--10);
  flex-direction: column;
  align-items: flex-start;
}
.wp-container-core-group-is-layout-16 {
  flex-direction: column;
  align-items: stretch;
}
.wp-container-core-navigation-is-layout-3 {
  gap: var(--wp--preset--spacing--10);
  flex-direction: column;
  align-items: flex-start;
}
.wp-container-core-group-is-layout-17 {
  gap: var(--wp--preset--spacing--10);
  flex-direction: column;
  align-items: flex-start;
}
.wp-container-core-group-is-layout-18 {
  flex-direction: column;
  align-items: stretch;
}
.wp-container-core-navigation-is-layout-4 {
  gap: var(--wp--preset--spacing--10);
  flex-direction: column;
  align-items: flex-start;
}
.wp-container-core-group-is-layout-19 {
  gap: var(--wp--preset--spacing--10);
  flex-direction: column;
  align-items: flex-start;
}
.wp-container-core-group-is-layout-20 {
  flex-direction: column;
  align-items: stretch;
}
.wp-container-core-group-is-layout-21 {
  justify-content: space-between;
  align-items: flex-start;
}
.wp-container-core-columns-is-layout-1 {
  flex-wrap: nowrap;
}
.wp-elements-69a005592d8de9c85f5c3744bd9a4e03
  a:where(:not(.wp-element-button)) {
  color: var(--wp--preset--color--contrast);
}

/* 28.css */

.skip-link.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.skip-link.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}
